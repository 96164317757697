<script setup>
import Multiselect from 'vue-multiselect';
import { GrTag } from '@/components/GrComponents';
import { GrSwitch } from '@/components/GrComponents/switch';
import { useStore } from '@/utils/useStore'
import { computed, ref, toRefs, watch } from 'vue';

const store = useStore()
const props = defineProps({
  setting: {
    type: Object,
    default: () => ({})
  }
})

const { setting } = toRefs(props)
const array = ref([
  {
    id: "HOURS",
    value: 'Horas'
  },
  {
    id: "MINUTES",
    value: 'Minutos'
  },
  {
    id: "DAYS",
    value: 'Dias'
  }
])
const scaleSelected = computed({
  get() {
    return setting.value.scale
  },
  set(event) {
    store.dispatch('opportunities/setValuKittenTime', { data: event, itemName: setting.value.name, varName: 'scale' })
    emit("functionPost")
  }
})
const activeSetting = computed({
  get() {
    return setting.value.active
  },
  set(value) {
    store.dispatch('opportunities/setValuKittenTime', { data: value, itemName: setting.value.name, varName: 'active' })
    emit("functionPost")
  }
})
const recoveryTimeSetting = computed({
  get() {
    return setting.value.recovery_time
  },
  set(value) {
    store.dispatch('opportunities/setValuKittenTime', { data: value, itemName: setting.value.name, varName: 'recovery_time' })
    emit("functionPost")
  }
})

const emit = defineEmits(['functionPost'])
</script>
<template>
  <div class="config-kitten">
    <div class="d-flex">
      <GrTag
        customBackground="var(--gray-10)"
        class="tag-method"
        variant="gray"
        theme="alternate"
      >
        {{ setting.name }}
      </GrTag>
      <div>
        <input id="profit_inputqtd_gatilho" type="number" class="input-number" v-model="recoveryTimeSetting" />
        <multiselect id="profit_selectescala_gatilho" class="select-kitten" v-model="scaleSelected" :allow-empty="false"
          :options="array" placeholder="" selectedLabel="" selectLabel="" deselectLabel="" tagPlaceholder=""
          label="value" track-by="id" />
      </div>
    </div>
    <div class="d-flex-switch">
      <span>
        {{ $t(setting.text) }}
      </span>
      <GrSwitch id="profit_toggle_ativargatilho" :value.sync="activeSetting" variant="info" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.config-kitten {
  width: 100%;
  display: flex;
  gap: var(--spacing-5);

  .d-flex {
    display: flex;
    gap: var(--spacing-5);

    div {
      display: flex;
      gap: var(--spacing-2);
    }
  }

  .d-flex-switch {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center
  }

  span {
    font-size: var(--font-base);
    font-weight: var(--weight-semibold);
    line-height: var(----spacing-6);
    color: var(--gray-200);
    text-align: left;
  }
}

.input-number {
  width: var(--w-15) !important;
  height: var(--h-14) !important;
  margin: 0 !important;
  padding: 10px !important;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :focus {
    outline: none !important;
    border: none !important;
  }
}

.tag-method {
  width: var(--w-32);
  height: var(--h-14);
  font-weight: var(--weight-semibold);
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss">
.select-kitten {
  #listbox-null {
    right: 0;
    top: 55px;
  }
}
</style>