import { render, staticRenderFns } from "./ListOpportunities.vue?vue&type=template&id=2551d960&scoped=true"
import script from "./ListOpportunities.vue?vue&type=script&setup=true&lang=js"
export * from "./ListOpportunities.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ListOpportunities.vue?vue&type=style&index=0&id=2551d960&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2551d960",
  null
  
)

export default component.exports